import React from 'react';
import { useSelector } from 'react-redux';

import CustomScrollbars from 'components/molecules/CustomScrollBars';
import Navigation from 'components/molecules/Navigation';
import { permissionList, usePermission } from 'hooks/usePermission';

const SideBarContent = () => {
  const { authUser } = useSelector((state) => state.auth);
  const { evaluate } = usePermission();
  const isMerchant = authUser.type === 'merchant';

  const rendersOptions = (array) => {
    return array.map((item) => {
      const hasPermission = evaluate(item?.required);

      item.type = hasPermission || item?.conditional ? 'item' : 'hide';
      return item;
    });
  };
  const userMenu = [
    {
      name: 'app.menu.customer',
      icon: 'menu',
      link: '/app/users/customer',
      required: [permissionList.customerRead],
    },
    {
      name: 'app.menu.merchant',
      icon: 'menu',
      link: '/app/users/merchant',
      required: [permissionList.userMerchantRead],
    },
    {
      name: 'app.menu.administrator',
      icon: 'menu',
      link: '/app/users/administrator',
      required: [permissionList.userRead],
    },
    {
      name: 'app.menu.banksByUser',
      icon: 'menu',
      link: '/app/users/banks',
      required: [permissionList.customerRead, permissionList.customerBankRead],
    },
  ];
  const fitbankMenu = [
    {
      name: 'app.menu.fitbanktransfer',
      icon: 'view-list-alt',
      required: [permissionList.manualTransaction],
      link: '/app/fitbank/transfer',
    },
    {
      name: 'app.menu.bot',
      icon: 'laptop-mac',
      required: [permissionList.transactionBankInfoRead],
      link: '/app/fitbank/bot',
    },
  ];
  const reportsMenu = [
    {
      name: 'app.menu.acamReports',
      icon: 'chart',
      required: [permissionList.acamReportRead],
      link: '/app/reports',
    },
    {
      name: 'app.menu.monthlyMerchantReport',
      icon: 'chart',
      required: [permissionList.merchantMonthlyReportsRead],
      link: '/app/monthly-report',
    },
    {
      name: 'app.menu.balancesHistory',
      icon: 'timer',
      link: '/app/wallet-balance-history',
      required: [permissionList.menuBalanceHistory],
    },
    {
      name: 'app.menu.balances',
      icon: 'chart',
      link: '/app/balances',
      conditional:
        evaluate([permissionList.menuBalances, permissionList.merchantRead]) ||
        evaluate([permissionList.bankRead]),
    },
    {
      name: 'app.menu.dailyReport',
      icon: 'chart',
      link: '/app/daily-report',
      required: [permissionList.summaryDailyTransactionsRead],
    },
    {
      name: 'app.menu.dailyReportByBank',
      icon: 'chart',
      link: '/app/daily-report-by-bank',
      required: [permissionList.summaryDailyTransactionsRead],
    },
    {
      name: 'app.menu.customerTransactions',
      icon: 'chart',
      link: '/app/customer-transactions',
      required: [permissionList.summaryTransactionsCustomerRead],
    },
    {
      name: 'app.menu.notifications',
      icon: 'chart',
      link: '/app/notifications-report',
      required: [permissionList.notificationRead],
    },
  ];

  const betaBiMenu = [
    {
      name: 'app.menu.conciliation',
      icon: 'chart',
      link: '/app/beta-bi-conciliation',
      required: [permissionList.superSetRead],
    },
  ];

  const approvalsMenu = [
    {
      name: 'app.menu.pendingTransactions',
      icon: 'menu',
      link: '/app/approvals/transactions',
      required: [
        permissionList.customerRead,
        permissionList.transactionReviewRead,
      ],
    },
    {
      name: 'app.menu.sof',
      icon: 'menu',
      link: '/app/approvals/sof',
      required: [permissionList.customerDocumentRead],
    },
  ];

  const navigationMenus = [
    {
      name: 'app.menu.dashboard',
      icon: 'view-dashboard',
      type: 'fixed',
      link: '/app/dashboard',
    },
    {
      name: 'app.menu.users',
      icon: 'account-box',
      type: rendersOptions(userMenu).some((item) => item.type === 'item')
        ? 'collapse'
        : 'hide',
      children: rendersOptions(userMenu),
    },
    {
      name: 'app.menu.pixAndGo.issues',
      icon: 'alert-polygon',
      type: evaluate([permissionList.pixAndGoRead]) ? 'item' : 'hide',
      link: '/app/pix-and-go-issues',
    },
    {
      name: 'app.menu.transactions',
      icon: 'view-list-alt',
      type: evaluate([permissionList.transactionRead]) ? 'item' : 'hide',
      link: '/app/transactions',
    },
    {
      name: 'app.menu.issues',
      icon: 'alert-polygon',
      type: evaluate([permissionList.transactionIssueRead]) ? 'item' : 'hide',
      link: '/app/issues',
    },
    {
      name: 'app.menu.fitbank',
      icon: 'tab',
      type: rendersOptions(fitbankMenu).some((item) => item.type === 'item')
        ? 'collapse'
        : 'hide',
      link: '/app/fitbank',
      children: rendersOptions(fitbankMenu),
    },
    {
      name: 'app.menu.merchants',
      icon: 'city',
      type:
        evaluate([permissionList.menuMerchants, permissionList.merchantRead]) &&
        !isMerchant
          ? 'item'
          : 'hide',
      link: '/app/merchants',
    },
    {
      name: 'app.menu.banks',
      icon: 'balance',
      type:
        evaluate([
          permissionList.menuBanks,
          permissionList.bankRead,
          permissionList.countTransactionsBanksRead,
        ]) && !isMerchant
          ? 'item'
          : 'hide',
      link: '/app/banks',
    },
    {
      name: 'app.menu.reports',
      icon: 'chart',
      type: rendersOptions(reportsMenu).some((item) => item.type === 'item')
        ? 'collapse'
        : 'hide',
      link: '/app/reports',
      children: rendersOptions(reportsMenu),
    },
    {
      name: 'app.menu.betaBi',
      icon: 'chart',
      type:
        rendersOptions(betaBiMenu).some((item) => item.type === 'item') &&
        !isMerchant
          ? 'collapse'
          : 'hide',
      link: '/app/beta-bi',
      children: rendersOptions(betaBiMenu),
    },
    {
      name: 'app.menu.approvals',
      icon: 'check-square',
      type:
        rendersOptions(approvalsMenu).some((item) => item.type === 'item') &&
        !isMerchant
          ? 'collapse'
          : 'hide',
      children: rendersOptions(approvalsMenu),
    },
    {
      name: 'app.menu.credentials',
      icon: 'menu',
      type:
        evaluate([permissionList.oAuthClientRead]) && isMerchant
          ? 'item'
          : 'hide',
      link: '/app/merchant-credentials',
    },
  ];

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation
        menuItems={navigationMenus}
        permissions={authUser?.permissions}
      />
    </CustomScrollbars>
  );
};

export default SideBarContent;
